.appTypographyLink {
  text-decoration: none;
  display: block;
  cursor: pointer;
  //color: theme('colors.gray-600');
  font-size: 14px;
  font-family: Inter, Arial, sans-serif;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.02em;
}

.underlineOnHover {
  &:hover {
    text-decoration: underline;
  }
}
