.react-tel-input input.form-control {
  height: 46px;
  border-color: theme('colors.divider');
  width: 100%;
  border-radius: 3px;
}

.react-tel-input .flag-dropdown {
  border-color: theme('colors.divider');
}


.MuiInputBase-formControl .MuiInputBase-input.MuiSelect-selectMenu {
  height: 46px;
  box-sizing: border-box;
}
