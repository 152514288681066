@tailwind components;
@tailwind utilities;


@import './loader.scss';
@import './map.scss';
@import './slider.scss';
@import './editor.scss';
@import './modifiers.scss';


.g-hide {
  display: none !important;
}

.g-fake-hide {
  position: absolute;
  left: -999999px;
  top: -999999px;
  opacity: 0.1 !important;
  visibility: hidden;
}

.container {
  @apply px-2;
  max-width: none !important;

  @screen xl {
    @apply px-0;
    max-width: theme('screens.xl') !important;
  }
}
