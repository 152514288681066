
.g-google-map__price-tag {
  background-color: white;
  color: #19647E;
  border-radius: 32px;

  font-size: 11px;
  font-weight: 500;
  padding: 4px 8px;
  position: relative;
  text-align: center;


  &::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translate(-50%, 0);
    width: 0;
    height: 0;
    border-left: 6px solid rgba(0, 0, 0, 0);
    border-right: 6px solid rgba(0, 0, 0, 0);
    border-top: 6px solid white;
  }

  &.-selected {
    color: #FFFFFF;
    background-color: #19647E;

    &::after {
      border-top: 6px solid #19647E;
    }
  }
}

.leaflet-container {
  z-index: 0; //default
}

.leaflet-tile-pane {
  filter: saturate(1.2) brightness(0.95) contrast(1.03);//a bit more color
}

.leaflet-div-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
}

.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
  border: none;

  a {
    color: #19647E;
  }
}


.leaflet-popup-content-wrapper {
  border-radius: 16px;

  .leaflet-popup-content {
    margin: 16px;
  }
}

.leaflet-control-attribution {
  display: none !important;
}

.leaflet-popup-content p {
  margin: 0 !important;
}
