.container {
    background: white;
    border-radius: 12px 12px 0 0;
    padding:20px;
    margin: 0 20px;
    display: block;
    position: fixed;
    bottom:0;
    left:0;
    right:0;
    z-index: theme('zIndex.under-modal');
    /* todo either use material ui or tailwind one */
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
    overflow-y:auto;
    max-height: 80%;
}

.text {
    font-size: 12px;

    @screen md {
        font-size: 14px;
    }
}
