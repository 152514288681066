.editor_content img {
  display: block;
  max-width: 100%;
  height: auto;
}



.editor_content p {
  margin-top: 1em;
  margin-bottom:1em;
}

.editor_content p, .editor_content a, .editor_content span, .editor_content li {
  font-family: Inter, Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.016em;
  color: #364259;
}


.editor_content.-first ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.editor_content.-first ul {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
}

@media (max-width: 768px) {
  .editor_content.-first ul {
    align-items: flex-start;
  }
}


.editor_content.-first ul li a {
  color: #1A2232;
  padding: 12px 24px;
  font-size: 14px;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: Inter, Arial, sans-serif;
  font-weight: 500;
  line-height: 24px;
  border-radius: 6px;
  letter-spacing: -0.016em;
  text-transform: none;
  text-decoration: none;
  border: 1px solid #1A2232;
}

.editor_content.-first ul li a:hover {
  text-decoration: none;
  color: white;
  background-color: #1A2232;
}

@media (hover: none) {
  .editor_content.-first ul li a:hover {
    background-color: rgba(26, 34, 50, 0.02);
  }
}


.editor_content h1 {
  font-family: Inter, Arial, sans-serif;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.03em;
}

.editor_content h2 {
  font-family: Inter, Arial, sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.03em;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
}

.editor_content h3 {
  font-family: Inter, Arial, sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.02em;
}
