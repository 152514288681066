.AppRoundedButton {
  border-radius: 3px;
  height: 40px;

  &.small {
    height: 36px;
  }

  & span {
    line-height: normal;
  }
}


.AppLoadingButton {
  :global(.MuiCircularProgress-colorPrimary) {
    color: white;
  }
}
